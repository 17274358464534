import axios from 'axios';
import { url } from '../config/index';

const api = axios.create({
	baseURL: url
});

api.interceptors.request.use(function(config) {
	const token = localStorage.getItem('token');
	config.headers.Authorization = token ? `Bearer ${token}` : '';
	return config;
});

export default api;
