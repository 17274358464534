import React, { useState } from 'react';
import './login.css';
import TextField from '@material-ui/core/TextField';
import Logo from '../../img/logo_probare.svg';
import { setToken } from '../../services/auth';
import { MoonLoader } from 'react-spinners';
import Swal from 'sweetalert2';

import api from '../../services/api';

export default function Login(props) {
	const [email, setEmail] = useState('');
	const [senha, setSenha] = useState('');
	const [loading, setLoading] = useState(false);
	const alertSwal = Swal.mixin({
		toast: true,
		position: 'top-end',
		showConfirmButton: false,
		timer: 2000
	});

	const handleLogin = async e => {
		e.preventDefault();
		setLoading(true);
		try {
			const response = await api.post('/login', { email: email, senha: senha });
			alertSwal.fire({
				type: 'success',
				title: 'Autenticado com Sucesso!'
			});
			setToken(response.data);
			setTimeout(() => {
				props.history.push('/home');
			}, 1000);
			setLoading(false);
		} catch (e) {
			if (e.response) {
				alertSwal.fire({
					type: 'error',
					title: e.response.data
				});
			} else {
				alertSwal.fire({
					type: 'error',
					title: 'Erro no Servidor!'
				});
			}
			setLoading(false);
		}
	};

	return (
		<div className='container-login'>
			<div className='title'>
				<div className='manipula-img'>
					<img src={Logo} alt='Logo' className='img-logo'></img>
				</div>
				<div className='header-title'>
					<span className='subtitle-1'>
						<b>Ouvidoria do Programa de Auto-Regulamentação</b>
					</span>
					<span className='subtitle-2'>
						<b>NOTÍCIA DE VIOLAÇÃO AO CÓDIGO DE ÉTICA</b>
					</span>
				</div>
			</div>

			<div className='bord-align'>
				<div className='bord' />
			</div>

			<div className='modalPosition'>
				<div className='modalLogin'>
					<span className='p-modal'>
						<b>Login</b>
					</span>
					<form onSubmit={handleLogin}>
						<div className='area-input'>
							<span className='label-input'>Email:</span>
							<TextField
								className='input-all'
								name='email'
								onChange={e => setEmail(e.target.value)}
								value={email}
							/>
							<span className='label-input'>Senha:</span>
							<TextField
								type='password'
								className='input-all'
								name='senha'
								onChange={e => setSenha(e.target.value)}
								value={senha}
							/>
						</div>

						<div className='footer-modal-login'>
							{/* <div className='check'>
								<span className='label-input-check-login'>Esqueci a minha senha</span>
							</div> */}
							<span></span>
							<button className='button-proximo' type='submit' disabled={loading}>
								{loading ? <MoonLoader sizeUnit={'px'} size={15} color={'#FFFFFF'} /> : 'Entrar'}
							</button>
						</div>
					</form>
				</div>
			</div>
			{/* <div className='footer'>
				<span className='paragrafo-1'>Termos de Uso</span>
				<span className='paragrafo-1'>Política de Privacidade</span>
			</div> */}
		</div>
	);
}
