import React, { useEffect, useState } from "react";
import { Route, Redirect } from "react-router-dom";

import { isAuthenticated } from "../services/auth";

const PrivateRoute = (props) => {

  const [auth, setAuth] = useState(false)
  const [isSubscribed, setIsSubscribred] = useState(false)

  useEffect(() => {
    isAuthenticated().then(response => {
      if(!isSubscribed) {
        setAuth(response)
        setIsSubscribred(true)
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    isSubscribed ?
      (auth ?
        <Route exact path={props.path} component={props.component} /> :
        <Redirect to="/" />)
      : null
  )
}


export default PrivateRoute;